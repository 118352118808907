@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';
.confirmModal {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px 40px;
	background-color: #fff;

	.icon {
		font-size: 50px;
		height: 120px;
		width: 120px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		&.warning {
			color: getColor(warning);
			background-color: getColor(secondary100);
		}
		&.danger {
			color: var(--ltErrorDark);
			background-color: getColor(error_bg);
		}
	}

	.content {
		margin-top: 20px;
		text-align: center;

		.title {
			font-size: 20px;
			font-weight: 700;
			margin-bottom: 10px;
			color: getColor(grey900);
		}

		.message {
			font-size: 16px;
			color: getColor(grey700);
		}
	}
	.footer {
		margin-top: 40px;
		display: flex;
		gap: 10px;
		width: 100%;

		.button {
			width: 100%;
			height: 40px;
		}
	}
}
