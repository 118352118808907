@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';

.select_container {
	width: 200px;
	position: relative;
	border: 1px solid getColor(grey300);
	background-color: #fff;
	border-radius: 6px;
	cursor: pointer;
	&::after {
		content: '';
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
		width: 0;
		height: 0;
		border-left: 5px solid transparent; /* Smaller border for a smaller arrow */
		border-right: 5px solid transparent; /* Smaller border for a smaller arrow */
		border-top: 5px solid getColor(grey500); /* Smaller and less sharp arrow */
		border-radius: 2px; /* Attempt to round the corners, effect might be subtle */
	}
}

.select_large {
	font-size: 16px;
	padding: 10px 12px;
}

.select_middle {
	font-size: 14px;
	padding: 8px 10px;
}

.select_small {
	font-size: 12px;
	padding: 6px 8px;
}

.select_selected {
	cursor: pointer;
	width: 100%;
	text-align: left;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: getColor(primary);
	font-size: 12px;
}

.select_options {
	position: absolute;
	top: 120%;
	left: 0;
	width: 100%;
	border: 1px solid getColor(grey300);
	border-top: none;
	border-radius: 6px;
	background-color: #fff;
	overflow-y: auto;
	z-index: 2;
	box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
	opacity: 0;
	transform: scaleY(0);
	transform-origin: top;
	transition:
		transform 0.2s ease-out,
		opacity 0.2s ease-out;
	animation: slide-down 0.3s ease-in-out;
	@keyframes slide-down {
		0% {
			opacity: 0;
			transform: translateY(-10px);
		}
		100% {
			opacity: 1;
			transform: translateY(0px);
		}
	}
}

.select_option {
	position: relative;
	padding: 13px 10px;
	padding-left: 40px;
	font-size: 16px;
	cursor: pointer;
	border-bottom: 1px solid rgba($greyD, 0.25);
	color: getColor(grey900);
	&:hover {
		background-color: rgba(11, 183, 131, 0.05);
		color: getColor(primary);
	}
	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}
	&::after {
		content: '';
		position: absolute;
		left: 10px;
		top: 50%;
		transform: translateY(-50%);
		width: 16px;
		height: 16px;
		border-radius: 50%;
		border: 1px solid getColor(grey400);
		background-color: #fff;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $primary; /* Color of the scroll thumb */
		border-radius: 20px; /* Roundness of the scroll thumb */
	}
	&:last-child {
		border-bottom: none;
	}
}
.select_option_selected {
	color: getColor(primary);
	position: relative;

	&::after {
		border: 1px solid getColor(primary);
		background-color: getColor(primary);

		//make radio bullet a white circle in piddle of green circle
	}
	&::before {
		content: '';
		position: absolute;
		left: 19px;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: #fff;
		z-index: 1;
	}
}
.select_options-entering,
.select_options-entered {
	opacity: 1;
	transform: scaleY(1);
}

.select_options-exiting,
.select_options-exited {
	opacity: 0;
	transform: scaleY(0);
	animation: slide-up 0.3s ease-in-out;
	@keyframes slide-up {
		0% {
			opacity: 1;
			transform: translateY(0px);
		}
		100% {
			opacity: 0;
			transform: translateY(-10px);
		}
	}
}
