@import '@/services/scss/variables.scss';

.card {
	display: flex;
	&.small {
		padding: 0.5rem; // Reduced padding
		font-size: 0.9rem; // Smaller text

		.cardBody {
			gap: 10px; // Reduced gap
		}

		.cardBodyLeft {
			gap: 0.25rem; // Reduced gap
		}

		.cardImage {
			height: 80px; // Smaller image
			width: 80px; // Smaller image
			border-radius: 9px;
			max-width: 80px; // Smaller image
			max-height: 80px; // Smaller image
			min-width: 80px; // Smaller image
			min-height: 80px; // Smaller image
		}

		.cardHeader {
			margin-bottom: 0.25rem; // Reduced margin
			height: 17px;
			text-overflow: ellipsis; // Ellipsis for long text
			overflow: hidden; // Hide overflow
			@supports (-webkit-line-clamp: 1) {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: initial;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
			}
		}

		.price,
		.mrp {
			font-size: 0.8rem; // Smaller font for price and mrp
		}

		.profit_percent {
			padding: 3px 15px 3px 10px; // Adjusted padding
			min-width: 50px; // Smaller width
			background-size: cover; // Adjust background size
			font-size: 0.8rem; // Smaller font
		}
	}
}
.cardBody {
	display: flex;
	flex: 1;
	gap: 20px;
}
.cardBodyLeft {
	display: flex;
	flex-direction: column;
	gap: 5px;
}
.cardImage {
	height: 117px;
	width: 117px;
	object-fit: cover; // This will ensure the image covers the area without stretching
	border-radius: 6px;
	border: 1px solid getColor(grey300);
	min-width: 117px;
	min-height: 117px;
	max-width: 117px;
	max-height: 117px;
}
.cardHeader {
	font-weight: 500;
	color: getColor(grey900);
	margin-bottom: 5px;
	a {
		text-decoration: none;
		color: getColor(grey900);
	}
}

.cardBody {
	color: #4a4a4a;
}

.price {
	border-radius: 9999px;
	font-weight: 700;
	color: #111827;
	margin-right: 10px;
}

.mrp {
	font-weight: 600;
	color: #718096;
	text-decoration: line-through;
}

.right {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.profit_percent {
	color: #fff;
	background-image: url('../../../assets/svg/discount.svg');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	font-weight: 600;
	padding: 5px 20px 5px 8px;
	margin-left: 10px;
	min-width: 65px;
	font-size: 14px;
	span {
		margin-left: 10px;
	}
}

.cardFooter {
	margin-top: 10px;
}

.outofstock {
	position: absolute;
	top: 40%;
	width: 100%;
	padding-inline: 20px;
}
.stocks_message {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #718096;
	border-radius: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 10px;
	color: #fff;
	font-weight: 600;
	padding: 2px 5px;
}
.deliveryDialog {
	margin-bottom: 2px;
	cursor: pointer;
	svg {
		height: 24px;
		width: 32px;
	}
}
