.toggleButton {
	display: flex;
	align-items: center;
	gap: 10px;
	width: 100%;

	.toggle_label {
		display: inline-flex;
		align-items: center;
		cursor: pointer;
		width: 100%;
	}

	.toggle_input {
		&:checked + .toggle_switch {
			background-color: var(--ltSuccessDark);

			&::after {
				transform: translateX(53px);
			}
		}
	}

	.toggle_switch {
		display: flex;
		align-items: center;
		position: relative;
		width: 80px;
		height: 30px;
		padding: 2px 8px;

		border: 1px solid #d1d5db; // gray-300

		border-radius: 9999px;
		transition: background-color 0.2s;
		background-color: var(--ltErrorDark);

		.online {
			margin-right: auto;
			color: #fff;
			
		}

		.offline {
			margin-left: auto;
			color: #fff;
		}

		&::after {
			content: '';
			width: 16px;
			height: 16px;

			position: absolute;
			// top: 4.5px;
			left: 2px;
			right: 4px;
			
			

			background-color: #fff;
			border: 1px solid #d1d5db; // gray-300
			border-radius: 9999px;
			transition: transform 0.2s;
		}
	}
}
