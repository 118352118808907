@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';


.slider_video_product {
    iframe,
    video {
        width: 100%;
        min-height: 530px;
        max-height: 530px;
    }
}


.brand_slider_wrap {
	position: relative;
	border-radius: 10px;
	overflow: hidden;
	//
	background-color: $white;
	border: 1px solid $grey300;

	.brand_slider {
		@include itemsCenter;
		padding: 10px $cp;
		position: relative;
		background-color: $white;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;

		img {
			margin-top: 10px;
			border-radius: 10px;
			width: 480px !important;
			height: 480px !important;
			object-fit: contain;
		}
	}

	.brand_slider_thumb {
		@include itemsCenter;
		padding: 10px $cp;
		position: relative;
		background-color: $white;
		border-radius: 10px;

		img {
			margin-top: 10px;
			border-radius: 10px;
			width: 50px !important;
			height: 50px !important;
		}
	}
}
.expressicon {
	position: absolute;
	bottom: 25px;
	left: 0px;
	cursor: pointer;
	min-width: 160px;
	height: 42px;

	span {
		@include itemsCenter;
		font-size: 18px;
		display: flex;
		font-weight: 400;
		padding-block: 5px;

		svg {
			margin: 0 $cp;
			height: 30px;
			width: 30px;
		}
	}

	span:first-child {
		@include itemsCenter;
		background-color: rgba(50, 59, 74, 0.1);

		color: getColor(grey900);
		border-radius: 3px;
		padding-left: 0px;
		padding-right: 20px;

		&:first-child svg {
			color: #ffd023;
			margin: 0;
			margin-right: 10px;
			margin-left: 15px;
		}
	}
}
.outofstockicon {
	position: absolute;
	bottom: 25px;
	left: 0px;
	cursor: pointer;
	min-width: 160px;
	height: 42px;
	color: white;
	border-radius: 6px;

	span {
		@include itemsCenter;
		font-size: 18px;
		display: flex;
		font-weight: 400;
		padding-block: 5px;
		color: white;

		svg {
			margin: 0 $cp;
			height: 30px;
			width: 30px;
		}
	}

	span:first-child {
		@include itemsCenter;
		background-color: black;

		color: getColor(white);
		border-radius: 3px;
		padding-left: 0px;
		padding-right: 20px;
	}
}
.shareicon {
	position: absolute;
	bottom: 25px;
	right: 10px;
	cursor: pointer;
	height: 42px;
	display: flex;
	gap: 20px;
	justify-content: center;
	align-items: center;
}
.next {
	position: absolute;
	bottom: 10px;
	left: 0px;
	transform: translateY(50%);
	cursor: pointer;
	background-color: white;
	opacity: 0.5;
	display: inline-block;
	width: 80%;
	text-align: center;
	svg {
		height: 30px;
		width: 30px;
	}
}
.prev {
	position: absolute;
	top: 10px;
	transform: translateY(-50%);
	cursor: pointer;
	background-color: white;
	opacity: 0.5;
	display: inline-block;
	width: 80%;
	text-align: center;
	svg {
		height: 30px;
		width: 30px;
	}
}
.brand_slider {
	height: 100%;
	overflow: hidden;
	position: relative;
	transition: all 0.5s ease;

	img {
		width: 100%;
		height: 618px;
		object-fit: contain !important;
	}
}
