.testDetails {
	padding-top: 30px;

	&_header {
		display: flex;
		align-items: center;
		gap: 10px;
		margin-bottom: 30px;

		span {
			display: block;
			font-size: 16px;
			font-weight: 300;
		}
		a {
			&:hover {
				color: var(--ltGreyscale600);
			}
		}
	}

	&_body {
		display: flex;
		justify-content: space-between;
		gap: 24px;

		.testDetails_leftSide {
			width: 100%;
			max-width: 60%;
			overflow-y: auto;
			margin-bottom: 20px;
			img {
				width: 100%;
				border: 1px solid #eeeff2;
				object-fit: cover;
			}

			.recommended_package {
				margin-bottom: 20px;
			}

			.process_works {
				margin-bottom: 30px;
			}
			.risk_assessment {
				display: flex;
				flex-direction: column;
				gap: 20px;
			}

			.faq_container {
				margin-top: 30px;
			}
			.faq_card_wrap {
				display: flex;
				flex-direction: column;
				gap: 20px;
			}
		}

		.testDetails_rightSide {
			width: 100%;
			max-width: 40%;
			overflow-y: auto;
			margin-bottom: 30px;
			display: flex;
			flex-direction: column;
			gap: 20px;

			.details_container {
				border: 1px solid #eeeff2;
				border-radius: 10px;
				overflow: hidden;

				.quickLinks {
					margin-bottom: 20px;
				}
			}
		}
	}
}

// @media screen and (max-width: 1024px) {
// 	.web_md_banner {
// 		display: block;
// 	}
// 	.web_lg_banner {
// 		display: none;
// 	}
// 	.web_xl_banner {
// 		display: none;
// 	}
// }

// @media screen and (min-width: 1024px) and (max-width: 1280px) {
// 	.web_md_banner {
// 		display: none;
// 	}
// 	.web_lg_banner {
// 		display: block;
// 	}
// 	.web_xl_banner {
// 		display: none;
// 	}
// }

// @media screen and (min-width: 1281px) {
// 	.web_md_banner {
// 		display: none;
// 	}
// 	.web_lg_banner {
// 		display: none;
// 	}
// 	.web_xl_banner {
// 		display: block;
// 	}
// }

// .testDetails_image_container::-webkit-scrollbar,
// .testDetails_details_container::-webkit-scrollbar {
// 	display: none;
// }
// .testDetails_image_container,
// .testDetails_details_container {
// 	-ms-overflow-style: none;
// 	scrollbar-width: none;
// }
