.doctorCheckoutTemp {
	margin-top: 30px;
	margin-bottom: 20px;
	width: 100%;
	max-width: 992px;
	min-height: 100vh;
	margin-left: auto;
	margin-right: auto;

	.header {
		margin-bottom: 30px;

		h5 {
			line-height: 32px;
			letter-spacing: 0.15px;
		}
	}
}
