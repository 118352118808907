@import '@/services/scss/variables.scss';

.range {
	padding-top: 20px;
	display: flex;
	align-items: center;
	gap: 5px;
	max-width: fit-content;
	justify-content: center;
	padding-inline: 20px;

	// .input {
	// 	height: 35px;
	// 	border: 1px solid #e0e0e0;
	// 	border-radius: 6px;
	// 	padding: 10px 15px;
	// 	font-size: 14px;
	// 	color: #4a4a4a;
	// 	outline: none;
	// 	&::placeholder {
	// 		font-size: 14px;
	// 	}
	// 	//hide arrows
	// 	&::-webkit-inner-spin-button,
	// 	&::-webkit-outer-spin-button {
	// 		-webkit-appearance: none;
	// 		margin: 0;
	// 	}
	// }
	.divider {
		margin: 0 10px;
		color: getColor(grey700);
		font-size: 14px;
		font-weight: 500;
	}
	.button {
		background-color: getColor(primary);
		color: white;
		border: none;
		border-radius: 6px;
		padding: 10px 15px;
		font-size: 14px;
		cursor: pointer;
		outline: none;
		transition: 0.3s;
		&:hover {
			background-color: darken(getColor(primary), 10%);
		}
	}

	.applyButton {
		background-color: getColor(white);
		border: none;
		height: 44px;
		width: 44px;
		min-width: 44px;
		min-height: 44px;
		border-radius: 50%;
		padding: 10px;
		font-size: 14px;
		cursor: pointer;
		outline: none;
		transition: 0.3s;
		display: flex;
		align-items: center;
		border: 1px solid getColor(grey300);
		&:hover {
			background-color: darken(getColor(white), 10%);
		}
	}
}

.floatingLabelInput {
	position: relative;
	display: flex;
	align-items: center;
	.currencySymbol {
		position: absolute;
		left: 10px; // Adjust as needed
		font-size: 14px; // Adjust to match input text size
	}

	label {
		position: absolute;
		left: 22px;
		top: 24%;
		transition:
			top 0.3s,
			font-size 0.3s;
		font-size: 14px; // Smaller font size for label
	}

	.input {
		width: 100%;
		padding: 8px 20px; // Reduced padding for a smaller input
		font-size: 14px; // Smaller font size for input text
		border-radius: 6px;
		text-wrap: wrap;
		border: 1px solid #e0e0e0;
		// outline: none;
		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
		&::placeholder {
			font-size: 14px;
		}
	}

	.focused {
		top: -20px;
		font-size: 12px;
	}
}

.list {
	padding: 0;
	margin: 0;
	list-style: none;
	max-height: 300px;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $primary; /* Color of the scroll thumb */
		border-radius: 20px; /* Roundness of the scroll thumb */
	}

	.listItem {
		display: flex;
		align-items: center;
		padding: 4px 0;
		label {
			font-size: 14px;
			color: getColor(grey700);
			margin-bottom: 0;
		}

		.checkbox {
			appearance: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			width: 24px; // Adjust size as needed
			height: 24px;
			border: 2px solid getColor(grey300);
			border-radius: 6px;
			margin-right: 10px;
			position: relative;
			outline: none;
			cursor: pointer;

			&:checked {
				background-color: getColor(primary);
				border-color: getColor(primary);

				&:before {
					content: '';
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translateX(-50%) rotate(45deg);
					height: 6px; // Adjust size as needed
					width: 2px; // Adjust thickness as needed
					background-color: white;
				}

				&:after {
					content: '';
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translateX(-50%) rotate(-45deg);
					height: 2px; // Adjust thickness as needed
					width: 10px; // Adjust size as needed
					background-color: white;
				}
			}
		}
	}

	.listItem:last-child {
		border-bottom: none;
	}
}

	@media (min-width: 576px) {
		.range {
			display: grid;
			grid-template-columns: repeat(1, 1fr);
		}
	}
	@media (min-width: 700px) {
		.range {
			display: grid;
			grid-template-columns: repeat(1, 1fr);
		}
	}
	@media (min-width: 800px) {
		.range {
			display: grid;
			grid-template-columns: repeat(1, 1fr);
		}
	}
	@media (min-width: 1024px) {
		.range {
			display: grid;
			grid-template-columns: repeat(1, 1fr);
		}
	}

	@media (min-width: 1100px) {
		.range {
			display: grid;
			grid-template-columns: repeat(1, 1fr);
		
		}
	}

	@media (min-width: 1200px) {
		.range {
			display: grid;
			grid-template-columns: repeat(1, 1fr);
			grid-gap: 5px;
		}
	}
	@media (min-width: 1280px) {
		.range {
			grid-template-columns: repeat(1, 1fr);
			grid-gap: 5px;
		}
	}

	@media (min-width: 1350px) {
		.range {
			display: flex;
		}
	}

	/* Extra large devices (1600px and up) */
	@media (min-width: 1600px) {
		.range {
			display: flex;
		}
	}
