.healthConcernCard {
	width: 100%;
	height: 211px;
	border: 1px solid var(--ltGreyscale300);
	border-radius: 10px;
	background: #fff;
	box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.04);

	&_image {
		width: 100%;
		height: 120px;
		border-radius: 10px 10px 0px 0px;
		background: #fff4ed;
		overflow: hidden;
	}

	&_content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		gap: 11px;
		padding: 15px;

		p {
			line-height: 22.4px;
		}
	}
}
