.incomingCallCard {
	width: 339px;
	border: 1px solid var(--ltGreyscale300);
	border-radius: 15px;
	background: var(--White);
	/* Golobal card shadow */
	box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.04);
	padding: 32px 32px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 44px;

	&_image {
		width: 165px;
		height: 165px;
		padding: 7px;
		border: 1px solid var(--ltPrimary);
		border-radius: 50%;
    margin-bottom: 20px;
	}

  &_info{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

	&_action {
		display: flex;
		gap: 24px;
	}
}
