.testCategory_container {
	margin-top: 30px;
	margin-bottom: 30px;
}
.testCategory_filtes_wrap {
	border: 1px solid #e5e5e5;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px;
}
.testCategory_slider {
	margin-top: 30px;
}
.testCategory_body {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 20px;
}
.testCategory_header {
	span {
		display: block;
	}
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 30px;
	gap: 5px;
	a {
		&:hover {
			color: var(--ltGreyscale600);
		}
	}
}
.testCategory_filterHeader {
	width: 40%;
}
.filters_container {
	width: 60%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.filterPrice {
	width: 350px;
	display: flex;
	align-items: center;
	margin-right: 20px;
	> p {
		text-wrap: nowrap;
		margin-right: 20px;
	}
	> div {
		width: 100%;
	}
}
.test_results_container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.testCard_container {
	margin-bottom: 20px;
}
.testList {
	> div {
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.spinner_wrap {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

@media (max-width: 1320px) {
	.testCategory_filterHeader h6 {
		font-size: 14px;
	}

	.filterPrice {
		display: none;
	}

	.testCategory_filterHeader {
		width: 100%;
	}

	.filters_container {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}
}

/* @media (min-width: 1201px) and (max-width: 1440px) {
	.testCategory_filterHeader h6 {
		font-size: 14px;
	}
	
}
 */
