.test_process_working_steps_head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 30px 0px;
	padding-right: 15px;
	h5 {
		margin-bottom: 0;
	}
}
.test_process_working_steps_head_slidericon {
	display: flex;
	align-items: center;
	gap: 24px;
}
.test_process_working_steps_cardrow {
	display: flex;
	justify-content: space-between;
	gap: 24px;
	width: 100%;
	overflow: scroll;
	&::-webkit-scrollbar {
		visibility: hidden;
	}
}
.test_process_working_steps_cardrow_eplipses {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 24px;
	margin-top: 60px;
}
