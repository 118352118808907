.popularPackages {
	margin-bottom: 30px;
}

.pPackages {
	&_header {
		margin-bottom: 30px;
	}

	&_all_cards {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 24px;
	}
}
