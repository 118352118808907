.doctorRegistrationBanner {
	background: #fff4ed;
	margin-bottom: 30px;
}

.rgBanner_container {
	display: flex;
	justify-content: flex-start;
	gap: 23px;

	.rgBanner_image {
		padding-top: 90px;
	}

	.rgBanner_content {
		padding: 90px 0px;

		.title {
			color: var(--ltGreyscale700);
			font-size: 32px;
			font-weight: 700;
			line-height: 40px;
			margin-top: 34px;
			margin-bottom: 23px;
		}

		.description {
			color: var(--ltGreyscale600);
			line-height: 24px;
			margin-bottom: 30px;
		}
	}
}
