.healthCard {
	display: flex;
	flex-direction: column;
	background: #ffffff;
	border: 1px solid #eeeff2;
	border-radius: 10px;
	overflow: hidden;
	cursor: pointer;

	&:hover {
		.healthCard_text_action {
			span {
				transform: translateX(10px);
			}
		}
	}

	.healthCard_text_action {
		span {
			transform: translateX(0px);
			transition: transform 0.2s ease;
		}
	}
}
.categoryPage_healthCard {
	border: none;
}
.healthCard_img {
	position: relative;
	height: 225px;
	width: 100%;
	background: #cbd5e0;
}
.categoryPage_healthCard_img {
	position: relative;
	height: 150px;
	width: 100%;
	background: #cbd5e0;
	border: none;
	border-radius: 10px;
	img {
		border: none;
		border-radius: 10px;
	}
}
.healthCard_img img:hover,
.categoryPage_healthCard_img img:hover {
	transition:
		box-shadow 0.2s ease,
		transform 0.2s ease;
	transform: scale(1.03);
}
.healthCard_img img,
.categoryPage_healthCard_img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.healthCard_text {
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 20px;
}
.healthCard_text_action {
	display: flex;
	gap: 10px;
	align-items: center;
	color: var(--ltPrimary);
}
