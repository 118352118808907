.vitalOrganCard {
	gap: 14px;
	display: flex;
	width: 100%;
	height: 100%;
	padding: 30px 0px;
	border-radius: 10px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	&.large {
		gap: 30px;
	}
	img {
		display: block;
		margin: 0 auto;
		&:hover {
			transition:
				box-shadow 0.2s ease,
				transform 0.2s ease;
			border: 1px solid #eeeff2;
			transform: scale(1.05);
		}
	}
	p {
		text-wrap: nowrap;
	}
}
.categoryPage_vitalOrganCard {
	padding: 40px 0px;
}

// Background Colors
.bgLavenderBlue_heart {
	background: #c8d7f5;
}
.bgPiggyPink_thyroid {
	background: #f9e0e6;
}
.bgChampagne_liver {
	background: #f8e9c8;
}
.bgLavenderBlue_bone {
	background: #c8d7f4;
}
.bgChampagne_lungs {
	background: #f8e9ca;
}
.bgPaleLavender_jointPain {
	background: #dcd8f3;
}
.bgPaleLavender_kidney {
	background: #dad5f3;
}
.bgPalePink_neuro {
	background: #fedcd5;
}

//  Text Colors

.clIndependence {
	color: #4a5568;
}
.clPurpleNavy_heart {
	color: #4b5f80;
}
.clMountbattenPink_thyroid {
	color: #a97d8a;
}
.clDarkTan_liver {
	color: #9d844c;
}
.clPurpleNavy_bone {
	color: #475b80;
}
.clDarkTan_lungs {
	color: #9d8755;
}
.clDarkBlueGrey_jointPain {
	color: #625e90;
}
.clDarkBlueGrey_kidney {
	color: #5f6090;
}
.clCopperPenny_neuro {
	color: #b66c6b;
}
