.doctorSubInfoCard {
	display: flex;
	align-items: center;
	gap: 20px;
	padding: 20px 20px 20px 20px;

	.icon_wrap {
		width: 48px;
		height: 48px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.details {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
}
