@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';
.categoryContent {
	.categoryProductsBlock {
		flex: 1 1 0;

		.productList {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-gap: 20px;
			padding: 20px 0;
		}
	}
}
.active {
	background-color: getColor(primary_bg);
	h5 {
		color: getColor(primary);
	}
	img {
		zoom: 1.2;
		transform: scale(1.2);
		animation: zoom 0.5s ease-in-out;
	}
	@keyframes zoom {
		0% {
			transform: scale(1);
		}
		100% {
			transform: scale(1.2);
		}
	}
}
.roundedTop {
	border-radius: 10px 10px 0 0 !important;
}

.category_card {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	border-radius: 6px;
	margin-bottom: 20px;
	.category_card__image {
		border-radius: 12px;
		align-items: center;
		display: flex;
		justify-content: center;
		background-color: #e5f3f3;
		height: 150px;

		img {
			width: 120px;
			height: 120px;
			object-fit: cover;
			margin-block: 10px;
		}
	}
	.category_card__content {
		margin-top: 10px;
		border-radius: 0 0 6px 6px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 7px;

		div {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: normal;
			color: #4a5568;
			height: 40px;
			min-height: 40px;
		}
	}



}

.card_grid_style {
	grid-template-columns: repeat(8, 1fr);
	@media (min-width: 576px) {
		.card_grid_style {
			grid-template-columns: repeat(3, 1fr);
		}
	}
	@media (min-width: 1024px) {
		.card_grid_style {
			grid-template-columns: repeat(4, 1fr);
		}
	}
	
	@media (min-width: 1100px) {
		.card_grid_style {
			grid-template-columns: repeat(4, 1fr);
		
		}
	}
	
	@media (min-width: 1200px) {
		.card_grid_style {
			grid-template-columns: repeat(5, 1fr);
			grid-gap: 5px;
		}
	}
	@media (min-width: 1280px) {
		.card_grid_style {
			grid-template-columns: repeat(4, 1fr);
			grid-gap: 5px;
		}
	}
	
	@media (min-width: 1400px) {
		.card_grid_style {
			grid-template-columns: repeat(6, 1fr);
		}
	}
	
	/* Extra large devices (1600px and up) */
	@media (min-width: 1600px) {
		.card_grid_style {
			grid-template-columns: repeat(8, 1fr);
		}
	}
}
