/* .categorySectionPage_head {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
} */
.categorySectionPage {
	margin-bottom: 30px;
	position: relative;
}
.categorySectionPage_head {
	>div {
		>p {
			color: var(--ltGreyscale600);
		}
	}
}
.categorySectionPage_head_slidericon {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 24px;
	position: absolute;
	width: 100%;
	top: 50px;
	div {
		z-index: 1;
	}
}
.categorySectionPage_cardrow {
	display: flex;
	justify-content: space-between;
	gap: 24px;
	width: 100%;
	overflow-x: scroll;
	&::-webkit-scrollbar {
		visibility: hidden;
	}
}
.categorySectionPage_cardrow_eplipses {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 24px;
	margin-top: 60px;
}
