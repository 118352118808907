.line {
	width: 1px;
	background: var(--ltGreyscale300);
	align-self: stretch;
}

.dashed {
	background: #fff;
	border: 1px dashed var(--ltGreyscale300);
}
