.healthConcerns {
	margin-bottom: 30px;
}

.healthConcernCardList_head {
	margin-bottom: 30px;
}

.healthConcernCardList {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	gap: 16px;
}
