.specialtyCard {
	display: flex;
  flex-direction: column;
  align-items: center;
	justify-content: center;
	gap: 20px;
	width: 100%;
	height: 211.5px;
	padding: 20px;
	border: 1px solid var(--ltGreyscale200);
	border-radius: 10px;
	background: #fff;
	box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.04);

	&_image {
		width: 100px;
		height: 100px;
		padding: 16px;
		border-radius: 100%;
		background: #fff4ed;
		overflow: hidden;
	}

	&_content > p {
		line-height: 22.4px;
	}
}


.healthCard_text {
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 20px;
}

.healthCard_text_action {
	display: flex;
	gap: 10px;
	align-items: center;
	color: var(--ltPrimary);
}
.healthCard .healthCard_text_action span {
	transform: translateX(0px);
	transition: transform 0.2s ease;
}

.healthCard:hover .healthCard_text_action span {
	transform: translateX(10px);
}

