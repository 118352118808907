.badge {
	display: flex;
	span{
		display: block;
		line-height: 22px;
		padding: 0 0 0 8px;
		border-radius: 2px 0 0 2px;
	}
}

// Variants

.successDark {
	background: var(--ltSuccessDark);
	fill: var(--ltSuccessDark);
}
.errorBase {
	background: var(--ltErrorBase);
	fill: var(--ltErrorBase);
}
.orange {
	background: var(--ltOrange);
	fill: var(--ltOrange);
}

.brightGrey {
	background: #e7f1f1;
	fill: #e7f1f1;
}

// Colors
.primaryBase {
	color: var(--ltPrimary);
}
