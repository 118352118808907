.container {
	width: 100%;
	max-width: 1320px;
	margin-left: auto;
	margin-right: auto;

	&.md {
		max-width: 888px;
	}

	&.lg {
		max-width: 1024px;
	}

	&.xl {
		max-width: 1440px;
	}
}

@media (min-width: 576px) {
	.container {
		max-width: 540px;
	}
}
@media (min-width: 768px) {
	.container {
		max-width: 720px;
	}
}
@media (min-width: 992px) {
	.container {
		max-width: 660px;
	}
}
@media (min-width: 1200px) {
	.container {
		max-width: 840px;
	}
}
@media (min-width: 1320px) {
	.container {
		max-width: 960px;
	}
}
@media (min-width: 1400px) {
	.container {
		max-width: 1080px;
	}
}
@media (min-width: 1600px) {
	.container {
		max-width: 1280px;
	}
}

/* @media (max-width: 768px) {
  .container {
    max-width: 736px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .container {
    max-width: 960px;
  }
}

 @media (min-width: 1025px) and (max-width: 1400px) {
  .container {
    max-width: 1140px;
  }
}

 @media (min-width: 1401px) and (max-width: 1536px) {
  .container {
    max-width: 1024px;
  }
} */

/* 
.container {
  width: 100%;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
}

.container.md {
  max-width: 888px;
}
.container.lg {
  max-width: 1024px;
}
.container.xl {
  max-width: 1440px;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1220px) {
  .container {
    max-width: 1160px;
  }
}
@media (min-width: 1240px) {
  .container {
    max-width: 1180px;
  }
}
@media (min-width: 1260px) {
  .container {
    max-width: 1200px;
  }
}
@media (min-width: 1270px) {
  .container {
    max-width: 1210px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1220px;
  }
}
@media (min-width: 1300px) {
  .container {
    max-width: 1260px;
  }
}
@media (min-width: 1360px) {
  .container {
    max-width: 1300px;
  }
}
@media (min-width: 1380px) {
  .container {
    max-width: 1320px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1340px;
  }
}
@media (min-width: 1420px) {
  .container {
    max-width: 1360px;
  }
}
@media (min-width: 1440px) {
  .container {
    max-width: 1380px;
  }
}
@media (min-width: 1460px) {
  .container {
    max-width: 1400px;
  }
}
@media (min-width: 1480px) {
  .container {
    max-width: 1420px;
  }
}
@media (min-width: 1500px) {
  .container {
    max-width: 1440px;
  }
}
@media (min-width: 1520px) {
  .container {
    max-width: 1460px;
  }
}
@media (min-width: 1540px) {
  .container {
    max-width: 1480px;
  }
}
@media (min-width: 1560px) {
  .container {
    max-width: 1500px;
  }
}
@media (min-width: 1580px) {
  .container {
    max-width: 1520px;
  }
}
 */
