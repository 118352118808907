.share_wrapper {
	display: flex;
	gap: 0.75rem;
	justify-content: center;
}

.shareIcon {
	color: #b5b9be;
	transition: color 250ms;
	&:hover {
		color: #108069;
	}
}
