// styles/List.module.scss
@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';

.list {
	padding: 0;
	margin: 0;
	list-style: none;
	max-height: 300px;
	overflow-y: auto;
	padding-inline: 20px;

	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $primary; /* Color of the scroll thumb */
		border-radius: 20px; /* Roundness of the scroll thumb */
	}

	.listItem {
		display: flex;
		align-items: center;
		padding: 4px 0;
		label {
			font-size: 14px;
			color: getColor(grey700);
			margin-bottom: 0;
		}

		.checkbox {
			appearance: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			width: 24px; // Adjust size as needed
			height: 24px;
			border: 2px solid color(grey300);
			border-radius: 6px;
			margin-right: 10px;
			position: relative;
			outline: none;
			cursor: pointer;

			&:checked {
				background-color: getColor(primary);
				border-color: getColor(primary);

				&:before {
					content: '';
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translateX(-50%) rotate(45deg);
					height: 6px; // Adjust size as needed
					width: 2px; // Adjust thickness as needed
					background-color: white;
				}

				&:after {
					content: '';
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translateX(-50%) rotate(-45deg);
					height: 2px; // Adjust thickness as needed
					width: 10px; // Adjust size as needed
					background-color: white;
				}
			}
		}
	}

	.listItem:last-child {
		border-bottom: none;
	}
}

.input {
	border: 1px solid color(grey300);
	border-radius: 6px;
	padding: 15px 15px;
	width: 100%;
	font-size: 14px;
	color: getColor(grey700);
	margin-bottom: 10px;
	height: 35px;
	outline: none;
	&::placeholder {
		font-size: 14px;
	}
}

.labelClassName {
	font-size: 12px !important;
	color: getColor(grey700);
	margin-bottom: 0;
	label {
		font-size: 12px !important;
		color: getColor(grey700);
		margin-bottom: 0;
		font-weight: 400;
	}
}

.radio_wrapper {
	display: flex;
	align-items: center;

	label {
		font-size: 14px;
		color: getColor(grey700);
		margin-bottom: 0;
	}

	.radio {
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		width: 20px; // Adjust size as needed
		height: 20px;
		border: 2px solid color(primary);
		border-radius: 50%;
		margin-right: 10px;
		position: relative;
		outline: none;
		cursor: pointer;

		&:checked {
			// background-color: getColor(primary);
			// border-color: getColor(primary);

			&:before {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				width: 14px;
				height: 14px;
				border-radius: 50%;
				background: white;
				transform: translateX(-50%) translateY(-50%);
			}

			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				width: 11px;
				height: 11px;
				border-radius: 50%;
				background: getColor(primary);
				transform: translateX(-50%) translateY(-50%);
			}
		}
	}
}
