@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';
.breadcrumb {
	display: flex;
	list-style: none;
	margin: 0 auto;
	margin-bottom: 20px;
	padding-inline-start: 0px;

	.breadcrumb-item {
		margin-right: 0.5rem;

		&:last-child {
			margin-right: 0;
		}
	}

	.breadcrumb_link {
		color: getColor(grey700);
		text-decoration: none;
		font-weight: 400;
		text-transform: capitalize;
		// font-size: 10px;
		&:hover {
			text-decoration: none;
			color: getColor(primary);
		}
	}

	.breadcrumb_label {
		color: getColor(grey500);
		text-transform: capitalize;
		// font-size: ;
	}

	.breadcrumb_separator {
		display: flex;
		align-items: center;
		margin-right: 0px;
		margin-left: 10px;

		svg {
			fill: getColor(grey500);
			width: 18px;
			height: 18px;
			margin-top: 6px;
		}
	}
}




@media (max-width: 1300px) {
	.breadcrumb {
		.breadcrumb_link {
			color: getColor(grey700);
			text-decoration: none;
			font-weight: 400;
			text-transform: capitalize;
			font-size: 10px;
			&:hover {
				text-decoration: none;
				color: getColor(primary);
			}
		}
	
		.breadcrumb_label {
			color: getColor(grey500);
			text-transform: capitalize;
			font-size: 10px;
		}

	}
}

@media (max-width: 1400px) {
	.breadcrumb_link {
		color: getColor(grey700);
		text-decoration: none;
		font-weight: 400;
		text-transform: capitalize;
		font-size: 13px;
		&:hover {
			text-decoration: none;
			color: getColor(primary);
		}
	}

	.breadcrumb_label {
		color: getColor(grey500);
		text-transform: capitalize;
		font-size: 13px;
	}
}


