@import '@/services/scss/variables.scss';

.prescription_image_container {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	margin-top: 1rem;
}

.guide_header {
	display: flex;
	align-items: center;
	border: 1px solid getColor(grey300);
	border-radius: 8px;
	padding: 10px;
	gap: 10px;
}
