// components/Pagination.module.scss

.pagination {
    display: flex;
    align-items: center;
}


.arrow {
    padding: 12px;
    border: 1px solid #EAECF0;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
    &:first-child {
        margin-right: 16px;
    }
    &:last-child {
        margin-left: 16px;
    }
}

.pageNumber {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: none;

    &:hover {
        background-color: #F9FAFB;
    }

    &.active {
        background-color: #F9FAFB;
        border: 1px solid #EAECF0;
    }

    &:disabled {
        background-color: #fff;
        border-radius: 10px;
        cursor: not-allowed;
        border-color: #EAECF0;
    }
}

.dots {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    width: 40px;
    color: #475467;
}
