@import '@/services/scss/variables.scss';

.sidebar {
	height: fit-content;
	min-height: calc(100vh - 100px);
	background-color: getColor(white);
	width: 25%;
	min-width: 25%;
	border: 1px solid getColor(grey300);
	padding: 0;
	.sidebarHeader {
		padding: 15px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid getColor(grey300);
		margin-bottom: 10px;
		font-size: 14px;
		font-weight: 600;
	}
}
.sticky {
	position: -webkit-sticky;
	position: sticky;
	top: 70px;
	max-height: calc(100vh - 140px);
	overflow-y: auto;
}

.productListSticky {
	position: -webkit-sticky;
	position: sticky;
	top: 70px;
	max-height: calc(100vh);
	overflow-x: hidden;
	overflow-y: auto;
}
