.profileForm_wrap {
	position: relative;
	border: 1px solid var(--ltGreyscale300);
	border-radius: 50%;
	.profileForm_avatar {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 120px;
		height: 120px;
		border-radius: 50%;
		background: var(--ltGreyscale300);
		color: var(--ltGreyscale500);
		overflow: hidden;

		.icon_wrap {
			border-radius: 50%;
			background: white;
			padding: 4px;
			// width: 24px;
			// height: 24px;
			display: flex;
			align-items: center;
			justify-content: center;

			position: absolute;
			z-index: 1;
			right: 9px;
			bottom: 2px;
		}

		&.sm {
			width: 44px;
			height: 44px;
		}

		&.md {
			width: 48px;
			height: 48px;
		}

		&.lg {
			width: 90px;
			height: 90px;
		}
	}
}
