.test_process_card {
	width: 100%;
	height: 360px;
	border: none;
	border-radius: 10px;
	overflow: hidden;
	position: relative;
	background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 100%);

	.image {
		width: 251px;
		height: 360px;
		border-radius: 10px;
		overflow: hidden;
	}
}
.card_body {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	border-radius: 10px;
}
.chip {
	width: 111px;
	height: 40px;
	border-radius: 0px 50px 50px 0px;
	background: var(--ltOrange);
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 40px;
	margin-bottom: 10px;
	h5 {
		font-size: 20px;
		font-weight: 700;
		line-height: 140%;
		color: white;
		margin-bottom: 0px;
	}
}
.text_container {
	padding: 20px;
	display: flex;
	flex-direction: column;
}
