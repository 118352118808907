@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';


.reviewInfo {
    display: flex;
    flex-direction: column;
    margin: 0;

    .reviewAction {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .actionIcon {
            cursor: pointer;
            font-size: 20px;
            color: #667085;
        }

        .menu {
            position: absolute;
            top: 30px; // Adjust as needed
            right: 0;
            background-color: white;
            border: 1px solid #ddd;
            border-radius: 4px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            z-index: 10;
        }

        .menuItem {
            padding: 8px 12px;
            cursor: pointer;
            border: none;
            background: none;
            width: 100%;
            text-align: left;
            font-size: 14px;

            &:hover {
                background-color: #f1f1f1;
            }
        }

        .date {
            color: var(--ltGreyscale600);
            font-size: 14px;
            margin-left: auto;
        }

        .user {
            color: var(--ltGreyscale600);
            font-size: 14px;
            margin-left: 12px;
        }
    }

    .reviewText {
        font-size: 16px;
        font-weight: 400;
        color: var(--ltGreyscale700);
        margin-top: 8px;
    }

    .reviewStatus {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: white;
        padding: 0 8px;
        height: 23px;
        border-radius: 9999px;
        background-color: var(--ltOrange);
        margin-top: 8px;
        width: fit-content;
    }

    .images {
        display: flex;
        gap: 14px;
        margin: 8px 0px;

        .image {
            width: 64px;
            height: 64px;
            border-radius: 5px;
            object-fit: cover;
            border: 1px solid var(--ltGreyscale300);
            cursor: pointer;
        }
    }
}